import { Component, OnDestroy, OnInit, ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, TitleStrategy } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { CommonService } from 'app/_services/common.services';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseRequestService } from 'app/_services/base.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { default as _rollupMoment } from 'moment';
import * as _moment from 'moment';
const moment = _rollupMoment || _moment;
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  styleUrls: ['./classy.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as unknown as MatFormFieldDefaultOptions
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  colfilterUpdate = new Subject<any>();
  isScreenSmall: boolean;
  navigation: Navigation;
  shiftinterval: any
  refreshmap: any = { 0: "Manual", 60000: "1 Min", 300000: "5 Min" }
  user: User;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  showadd: boolean = false;
  buttontext: string = ''
  currenttab: any = {};
  ro: any = {};
  form = this.fb.group({
    dash: ['RO'],
    search: ['']
  })

  formalerts =
    this.fb.group({
      dash: ['Open'],
    })
  formreports =
    this.fb.group({
      dash: ['Individual'],
    })
  columns: any = [];
  colfilter: any = {};
  refreshed = 0;
  client: any = '';
  shifts: any = [];
  /**
   * Constructor
   */
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  currentalertss: string = 'Open'
  refreshtime: any = 0;
  inter: NodeJS.Timer;
  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public api: BaseRequestService,
    private _navigationService: NavigationService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _userService: UserService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private cd: ChangeDetectorRef
  ) {
    this.commonService.addText.subscribe(values => {
      this.buttontext = values
      this.cd.detectChanges();
    })
    this.commonService.addChange.subscribe(values => {
      this.showadd = values
      this.cd.detectChanges();
    })
    this.commonService.refreshtime.subscribe(values => {
      this.refreshtime = values;
      this.cd.detectChanges();

    })
    this.commonService.columns.subscribe(values => {
      this.columns = values
      this.cd.detectChanges();
    })

    this.commonService.searchdata.subscribe(values => {
      this.colfilter = values
      this.cd.detectChanges();
    })

    this.colfilterUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.commonService.searchfiler(this.colfilter)
      });

  }


  columnFilter(key, value) {
    this.colfilterUpdate.next(key)
    //    this.commonService.searchfiler(this.colfilter)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  ngAfterViewInit() {
    // const datepickersArray = this.datepickers.toArray();
    // console.log(datepickersArray);
    // Additional logic for the initialized datepickers
    this.refreshperinterval()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    console.log("dash", this.formalerts)
    this.form.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.dash(selectedValue)
    })
    this.formalerts.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.alertss(selectedValue)
    })

    this.form.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.dash(selectedValue)
    })
    this.formreports.get('dash').valueChanges.subscribe(selectedValue => {
      this.commonService.alertss(selectedValue)
    })

    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to the user service
    this._userService.user$
      .pipe((takeUntil(this._unsubscribeAll)))
      .subscribe((user: User) => {
        this.user = user;
        this.user = JSON.parse(localStorage.getItem('user'))
        console.log('user', user);
        // this.user.ro_data=["187648"]
        if (this.user && this.user.ro_data && this.user.ro_data.length > 0) {
          this.fetchro()
          this.fetchshifts()
        }
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this.commonService.currenttab.subscribe(values => {
      this.currenttab = values
      this.cd.markForCheck();
    })
    if (window.location.href.includes('ioml')) {
      this.client = 'ioml'
    }
    else {
      this.client = 'iocl'
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  columnFilterrangelsr(val: any, col: any): void {
    var searchedColName = col;
    // console.log("value", this.start)
    var dateDoc: any = {};
    console.log("value", this.range.value)
    dateDoc.startDate = this.range.controls.start.value
    dateDoc.endDate = this.range.controls.end.value
    if (dateDoc.startDate !== '' && dateDoc.endDate !== '' && dateDoc.endDate !== null && dateDoc.startDate !== null) {
      const gtr = (dateDoc.startDate).format('DD-MMM-YYYY')
      const lsr = (dateDoc.endDate).format('DD-MMM-YYYY')
      const values = { "$gte": gtr, "$lte": lsr }
      dateDoc = { 'startDate': '', 'endDate': '' }

      this.colfilterUpdate.next({ value: values, col });
    }
  }


  clearDatetimePicker(index: number, column: any) {
    // const picker = this.datetimePickers[index];
    this.colfilter[column.column] = null
    this.commonService.searchfiler(this.colfilter)
    // this.colfilterUpdate.next(null)
    // picker.value = null; // Clear the selected date and time
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  add(type) {
    this.commonService.create({ 'create': this.showadd, 'button': this.buttontext, 'type': type })
  }

  search() {

  }

  refresh(time) {
    this.commonService.refreshtext(time)
    this.refreshtime = time
    console.log("refreshed")
    this.refreshed = this.refreshed + 1
    this.commonService.refresh(this.refreshed)
  }



  refreshperinterval() {
    // if(this.refreshtime != 0 && this.refreshtime != undefined){
    this.inter = setTimeout(() => {
      this.refreshperinterval()
      if (this.refreshtime != 0 && this.refreshtime != undefined) {
        this.refreshed = this.refreshed + 1
        this.commonService.refresh(this.refreshed)
      }
    }, this.refreshtime);
    // }
  }



  fetchro() {
    let q = { "ro_code": String(this.user.ro_data[0]) }
    this.api.doRequest(`/api/ro_onboard/pull_romaster`, 'post', q).subscribe((result: any) => {
      if (result && result.data && result.data.length > 0 && result.status == 'success') {
        this.ro = result.data[0]
      }
    })

  }


  fetchshifts() {
    let q = {
      "query": {
        "bool": {
          "must": [
            {
              "match": {
                "ro_master_ref.ro_code.keyword": String(this.user.ro_data[0])
              }
            }, {
              "match": {
                "shift_type.keyword": "D"
              }
            }
          ]
        }
      }
    }
    // let q = {"ro_master_ref.ro_code": String(this.user.ro_data[0])}
    this.api.doRequest(`/api/shift_master`, 'get', null, { q: q }).subscribe((result: any) => {
      if (result && result.data && result.data.length > 0) {
        this.shifts = result.data
        console.log("shifts", result.data)
        this.shiftinterval = setInterval(() => {
          if (this.user.role.toLocaleLowerCase() == 'rodealer') {
            this.triggershiftcheck();
          }
        }, 60000);
      }
    })
  }

  triggershiftcheck() {
    const now = new Date();

    // Extract hours and minutes
    const localTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    this.shifts.forEach(ele => {
      if (localTime === ele.shift_open_time) {
        // console.log("The current time matches the shift_open_time.");
        const confirmation = this._fuseConfirmationService.open({
          title: `Start Dealer Shift`,
          message: `Do you wish to start ${ele.shift_name}?`,
          actions: {
            confirm: {
              label: 'Yes'
            }
          }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {
          if (result === 'confirmed') {
            this.startshift(ele)
          }
        })
      } else {
        console.log("The current time does not match the shift_open_time.");
      }
    })
  }


  startshift(shift) {
    var data = {
      id: shift._id,
      batch_ended: "",
      user_Ref: {
        id: this.user._id,
        name: this.user.name,
        role: this.user.role
      }
    }
    this.api.doRequest(`/api/shift_log/shift_open`, 'post', data).subscribe((result: any) => {
      if (result && result.data && result.data.length > 0) {
        this.shifts = result.data
        console.log("shifts", result.data)
        this.shiftinterval = setInterval(() => {
          this.triggershiftcheck();
        }, 60000);
      }
    })
  }
}
