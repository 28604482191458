<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark text-blue-50 bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-start w-full p-4">
            <!-- Logo -->
            <div class="flex flex-auto items-start ml-4 justify-start">
                <img *ngIf="client === 'iocl'" class="w-24" src="assets/images/logo/logo.png">
                <img *ngIf="client === 'ioml'" class="w-24" src="assets/images/logo/iomllogo.jpeg">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!--<notifications></notifications>
                <user [showAvatar]="false"></user>-->
            </div>
        </div>
        <p class="flex flex-row justify-start text-1xl pl-4 pb-2 text-center"
            *ngIf="this.user && this.user.ro_data && this.user.ro_data.length>0 && ro && ro.ro_name">{{ro.ro_name}}</p>
        <!-- User -->
        <!--<div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user.avatar"
                    [src]="user.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div>
        </div>-->
    </ng-container>
    <!-- Navigation footer hook -->
    <!--<ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                class="max-w-36"
                src="assets/images/logo/logo.png">
        </div>
    </ng-container>-->
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <p class="ml-2 md:text-3xl text-md text-extrabold font-extrabold tracking-tight leading-none">{{currenttab.tab}}
        </p>
        <p class="ml-2 md:text-2xl text-sm text-bold text-secondary" *ngIf="currenttab.showcount && currenttab.count>0">
            ({{currenttab.count}})</p>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--<languages></languages>-->
            <!-- <form [formGroup]="form">
                    <mat-form-field class="w-full mt-4 mat-mdc-form-field">
                        <input
                            matInput type="text" placeholder="Search.." name="search"
                            formControlName="search">
                            <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    </mat-form-field>

            </form> -->
            <!-- <button *ngIf="showadd && buttontext !== 'RO Onboard' && user && user.role !== 'Viewer'" mat-flat-button
                color="primary" (click)="add('NA')">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 hidden md:block">{{buttontext}}</span>
            </button>

            <button *ngIf="showadd && buttontext === 'RO Onboard' && user && user.role !== 'Viewer'" mat-flat-button
                color="primary" [matMenuTriggerFor]="roonboard">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1 hidden md:block">{{buttontext}}</span>
            </button> -->
            <button *ngIf="showadd && buttontext !== 'RO Onboard' && user && user.role !== 'Viewer'" mat-flat-button
                color="primary" [id]="buttontext" [name]="buttontext" class="hidden md:flex items-center"
                (click)="add('NA')">
                <mat-icon *ngIf="buttontext !=='Download'" [svgIcon]="'heroicons_outline:plus'" class="mr-1"></mat-icon>
                <mat-icon class="" *ngIf="buttontext ==='Download'" [svgIcon]="'mat_outline:download'"></mat-icon>
                <span>{{ buttontext }}</span>
            </button>

            <button *ngIf="showadd && buttontext !== 'RO Onboard' && user && user.role !== 'Viewer'" color="primary"
                class="block md:hidden" [id]="buttontext" [name]="buttontext" (click)="add('NA')">
                <mat-icon class="" *ngIf="buttontext !=='Download'" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <mat-icon class="" *ngIf="buttontext ==='Download'" [svgIcon]="'mat_outline:download'"></mat-icon>
            </button>

            <button *ngIf="showadd && buttontext === 'RO Onboard' && user && user.role !== 'Viewer'" mat-flat-button
                color="primary" class="hidden md:flex items-center" [id]="buttontext" [name]="buttontext"
                [matMenuTriggerFor]="roonboard">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <!-- <span>Status</span> -->
                <span class="ml-2 mr-1 pt-2">{{buttontext}}</span>
            </button>

            <button *ngIf="showadd && buttontext === 'RO Onboard' && user && user.role !== 'Viewer'" color="primary"
                class="block md:hidden" [id]="buttontext" [name]="buttontext" [matMenuTriggerFor]="roonboard">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <!-- <span>Status</span> -->
            </button>

            <div class="flex shrink-0 items-center sm:ml-4" *ngIf="currenttab.tab === 'ALERTS'">
                <form [formGroup]="formalerts">
                    <mat-button-toggle-group matInput formControlName="dash" name="dash" toggleType="radio"
                        aria-label="Font Style">
                        <mat-button-toggle value="Open">Open</mat-button-toggle>
                        <mat-button-toggle value="All">All</mat-button-toggle>
                    </mat-button-toggle-group>
                </form>
            </div>
            <div class="flex shrink-0 items-center sm:ml-4" *ngIf="currenttab.tab === 'REPORTS'">
                <form [formGroup]="formreports">
                    <mat-button-toggle-group matInput formControlName="dash" name="dash" toggleType="radio"
                        aria-label="Font Style">
                        <mat-button-toggle *ngIf="this.user.role ==='SuperAdmin'"
                            value="Summary">Summary Reports</mat-button-toggle>
                        <mat-button-toggle value="Individual">Individual Reports</mat-button-toggle>
                    </mat-button-toggle-group>
                </form>
            </div>
            <mat-menu class="user-status-menus" #roonboard="matMenu">
                <button mat-menu-item (click)="add('manual')">
                    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                    <span>Create Manually</span>
                </button>
                <button mat-menu-item (click)="add('excel')">
                    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                    <span>Excel Upload</span>
                </button>
            </mat-menu>

            <div class="flex shrink-0 items-center sm:ml-4" *ngIf="currenttab.tab === 'DASHBOARD'">
                <form [formGroup]="form">
                    <mat-button-toggle-group matInput
                        *ngIf="user && (user.role === 'SuperAdmin' || user.role === 'ClientAdmin' || user.role === 'ServerAdmin' )"
                        formControlName="dash" name="dash" toggleType="radio" aria-label="Font Style">
                        <mat-button-toggle value="RO">RO</mat-button-toggle>
                        <mat-button-toggle value="DSM">Sales</mat-button-toggle>
                    </mat-button-toggle-group>
                </form>
            </div>
            <div
                *ngIf="currenttab.tab !== 'DASHBOARD' && currenttab.tab !== 'REPORTS' && currenttab.tab !== 'SUMMARY' && currenttab.tab !=='USER MANUAL'">
                <button
                    *ngIf="columns && columns.length>0 && formalerts.value.dash != 'All' && showadd && currenttab.tab !== 'RETAIL OUTLET' "
                    class="min-w-10 min-h-7 h-7 px-2 leading-6" [matMenuTriggerFor]="filterMenu">

                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:search'"></mat-icon>
                </button>
                <button
                    *ngIf="columns && columns.length>0 && formalerts.value.dash != 'All' && (currenttab.tab === 'TRANSACTIONS' || currenttab.tab ==='AUDIT LOG' || currenttab.tab ==='ALERTS' || currenttab.tab ==='INTERLOCK'|| currenttab.tab ==='DU LOG' ) "
                    class="min-w-10 min-h-7 h-7 px-2 leading-6" [matMenuTriggerFor]="filterMenu">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:search'"></mat-icon>
                </button>
                <button *ngIf="columns && columns.length>0 && (currenttab.tab === 'RETAIL OUTLET')"
                    class="min-w-10 min-h-7 h-7 px-2 leading-6" [matMenuTriggerFor]="filterMenu">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:search'"></mat-icon>
                </button>
                <!-- <button (click)="refresh()" *ngIf="formalerts.value.dash != 'All'"
            class="md:min-w-10 md:min-h-7 md:h-7 md:px-2 mt-1 md:leading-6"
            >
            <mat-icon
                class="fa-xs"
                [svgIcon]="'refresh'"></mat-icon>
            </button> -->


                <button *ngIf="formalerts.value.dash != 'All'" color="primary"
                    class="md:min-w-10 md:min-h-7 md:h-7 md:px-2 mt-1 md:leading-6"
                    [matMenuTriggerFor]="refreshinterval">
                    <div class="flex flex-col justify-center items-center">
                        <mat-icon [svgIcon]="'refresh'"></mat-icon>
                        <p class="text-secondary text-xs">{{this.refreshmap[this.refreshtime]}}</p>
                    </div>
                </button>

                <mat-menu class="user-status-menus" #refreshinterval="matMenu">
                    <button mat-menu-item id="now" name="now" (click)="refresh(0)">
                        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                        <span>Now</span>
                    </button>
                    <button mat-menu-item id="1min" name="1min" (click)="refresh(60000)">
                        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                        <span>1 Min</span>
                    </button>
                    <button mat-menu-item id="5min" name="5min" (click)="refresh(300000)">
                        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
                        <span>5 Min</span>
                    </button>
                </mat-menu>

                <mat-menu #filterMenu="matMenu">
                    <mat-card class="pt-2 pb-2 filter-card max-100 flex flex-wrap" (click)="$event.stopPropagation();">
                        <h3 class="mb-1 ml-4">Filter</h3>
                        <mat-card-content>
                            <form *ngIf="columns && columns.length>0">
                                <ng-container *ngFor="let column of columns">
                                    <mat-form-field
                                        *ngIf="column && column.issearch && !column.showCalander && !column.showDates "
                                        class="w-60">
                                        <input matInput type="text" placeholder="Search {{column.header}}"
                                            *ngIf="!column.selectFilter && !column.showCalander && column.filter !== 'map' "
                                            name="{{column.column}}" [(ngModel)]="colfilter[column.column]"
                                            (input)='columnFilter($any($event.target).value,column.column)'>
                                        <mat-select placeholder="Choose {{column.header}}" name="{{column.column}}"
                                            *ngIf="column.filterdatalist && column.filterdatalist.length>0"
                                            [(ngModel)]="colfilter[column.column]"
                                            (selectionChange)="columnFilter($event.value, column.column)">
                                            <mat-option value="">All</mat-option>
                                            <mat-option *ngFor="let val of column.filterdatalist"
                                                [value]="val.value">{{val.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <mat-form-field *ngIf="column && column.showCalander"  class="w-60">
                            <mat-label>{{column.header}}</mat-label>
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                              <input matStartDate formControlName="start" placeholder="Start date">
                              <input matEndDate formControlName="end" placeholder="End date"
                              (dateChange)='columnFilterrangelsr($event.value,column.column)'>
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker disabled="false">
                            </mat-date-range-picker>                      
                          </mat-form-field> -->
                                    <!-- <mat-form-field *ngIf="column.iscolumnSearch && column.showDates" appearance="fill" class="w-100">
                          <mat-label>{{column.header}}</mat-label>
                         
                          <input matInput   [formControl]="datei"  [matDatepicker]="dp"
                          (dateChange)='columnFilterdate($event.value,column.column)'>
                          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                          <mat-datepicker #dp></mat-datepicker>   
                      </mat-form-field> -->
                                </ng-container>
                            </form>
                        </mat-card-content>
                    </mat-card>
                </mat-menu>


            </div>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!--<search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>-->

            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->